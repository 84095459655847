import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from "gatsby-image";
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import CresLogo from '../../assets/logoCRES.svg';
import BHPLogo from '../../assets/logoBHP.svg';
import SawuLogo from '../../assets/logoSAWU.svg';
import South32Logo from '../../assets/logoSOUTH32.svg';
import AdaptLogo from '../../assets/logoAdapt.svg';
import InformiaLogo from '../../assets/logoInformia.svg';
import WeighItLogo from '../../assets/weighitLogo.svg';
import ActionLink from '../../components/action-link';


const Description = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 4rem;
  order: 2;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.25;

  img {
    max-width: 200px;
    max-height: 100px;
    margin-bottom: 1rem;

    &.south32 {
      max-width: 100px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
        width: 100px;
      }
    }

    
  }

  a {
    width: 8.5rem;
    margin-top: 1rem;
  }

  @media only screen and (max-width : 700px) {
    padding: 2rem;
  }

  @media only screen and (max-width : 400px) {
    padding: 1rem;
  }
`,
Image = styled(Img)`
  order: 3;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  @media only screen and (max-width : 700px) {
    order: 1;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
  }
`,
Project = styled('section')`
  display: flex;
  width: 100%;
  align-items: stretch;
  margin-bottom: 2rem;
  border-radius: 15px;
  background: #F3F3F3;

  div { 
    min-width: 50%;
  }

  &:nth-of-type(odd) > div:first-of-type {
    order: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  @media only screen and (max-width : 700px) {
    flex-direction: column;

    &:nth-of-type(odd) > div:first-of-type {
      order: 1;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 0;
    }
  }
`,
Work = () => {
  const {imgCres, mobileImage, adaptJobs, bhpMobile, sawuDashboard, take5iphone, weighIt} = useStaticQuery(graphql`
    query {
      imgCres: file(relativePath: { eq: "cres/screenHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: file(relativePath: { eq: "informia/desktopHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bhpMobile: file(relativePath: { eq: "bhp/mobileRegister2.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adaptJobs: file(relativePath: { eq: "adapt/ipadJobs.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sawuDashboard: file(relativePath: { eq: "sawu/desktopDashboard.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      take5iphone: file(relativePath: { eq: "south32/take5iphone.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      weighIt: file(relativePath: { eq: "weighIt/mobileCar.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Delivering solutions big and small</h2>
        <p>Take a look at some of our recent and ongoing work below to learn more about what we've done and what we could do for your business.</p>
      </Intro>

      <section css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 2rem;
      `}>
        <Project to="/work/c-res">
          <Image fluid={imgCres.sharp.fluid} fadeIn />
          <Description>
            <img src={CresLogo} alt="C-Res logo" />
            <p>Enabling small local businesses to supply goods and services to BHP</p>
            <ActionLink to="/work/c-res">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project to="/work/bhp">
          <Image fluid={bhpMobile.sharp.fluid} fadeIn />
          <Description>
            <img src={BHPLogo} alt="BHP logo" />
            <p>Helping BHP register and track contractors with a multilingual system</p>
            <ActionLink to="/work/bhp">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project to="/work/adapt">
          <Image fluid={adaptJobs.sharp.fluid} fadeIn />
          <Description>
            <img src={AdaptLogo} alt="Adapt logo" className="adapt" />
            <p>Integrating multiple systems to increase productivity and efficiency</p>
            <ActionLink to="/work/adapt">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project to="/work/sawu">
          <Image fluid={sawuDashboard.sharp.fluid} fadeIn />
          <Description>
            <img src={SawuLogo} alt="SAWU logo" />
            <p>Expanding the Local Buying Program to South America via a multilingual application</p>
            <ActionLink to="/work/sawu">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project to="/work/south32">
          <Image fluid={take5iphone.sharp.fluid} fadeIn />
          <Description>
            <img src={South32Logo} alt="South32 logo" className="south32" />
            <p>Enabling South32 to conduct safety audits across their assets</p>
            <ActionLink to="/work/south32">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project to="/work/informia">
          <Image fluid={mobileImage.sharp.fluid} fadeIn />
          <Description>
            <img src={InformiaLogo} alt="Informia logo" />
            <p>Providing a real-time media monitoring and business intelligence service</p>
            <ActionLink to="/work/informia">Learn more...</ActionLink>
          </Description>
        </Project>

        <Project>
          <Image fluid={weighIt.sharp.fluid} fadeIn />
          <Description>
            <img src={WeighItLogo} alt="WeighIt logo" />
            <p>Helping companies comply with SOLAS regulations in the shipping industry</p>
            <ActionLink to="/work/weighIt">Learn more...</ActionLink>
          </Description>
        </Project>

      </section>
    </Layout>
  );
};

export default Work;
